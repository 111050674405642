<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >cron表达式在线生成</h2>
	<div style="margin-top: 4%;">
			<el-col :sm="17" style="margin: 0 auto;">
				<el-form :model="cronData" label-position="left" label-width="100px" ref="form">
					<el-tabs v-model="activeName" @tab-click="handleClick" v-if="cronData.tabsShow">
					    <!-- 秒逻辑 -->
						<el-tab-pane label="秒" name="second" >
							<el-radio-group style="margin-left: 0%;" v-model="cronData.secondRadio">
							  <el-radio label="1"  @change="generate" >允许的通配符[, - * /]</el-radio>
							</el-radio-group>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="2" v-model="cronData.secondRadio" @change="generate">周期</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input  v-model.number="cronData.startSecond" @input="checkOrinput('2')">
									<template v-slot:prepend>从</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input  v-model.number="cronData.endSecond" @input="checkOrinput('2')">
									<template v-slot:append>秒</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 18px;">
								<el-col :sm="2">
									  <el-radio label="3" v-model="cronData.secondRadio" @change="generate">指定</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input  v-model.number="cronData.startSecondInit" @input="checkOrinput('3')">
									<template v-slot:prepend>从</template>
									<template v-slot:append>秒开始</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input  v-model.number="cronData.secondCyc" @input="checkOrinput('3')">
									<template v-slot:prepend>每</template>
									<template v-slot:append>秒执行一次</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="4" v-model="cronData.secondRadio" @change="generate">自定义</el-radio>
								</el-col>
							</el-row>
								<el-row :gutter="20" style="margin-top: 1px;">
									<el-col :sm="24">
										<el-checkbox-group v-model="cronData.secondCheck"  @change="checkOrinput('4')">
											<el-checkbox label="0">00</el-checkbox>
											<el-checkbox label="1">01</el-checkbox>
											<el-checkbox label="2">02</el-checkbox>
											<el-checkbox label="3">03</el-checkbox>
											<el-checkbox label="4">04</el-checkbox>
											<el-checkbox label="5">05</el-checkbox>
											<el-checkbox label="6">06</el-checkbox>
											<el-checkbox label="7">07</el-checkbox>
											<el-checkbox label="8">08</el-checkbox>
											<el-checkbox label="9">09</el-checkbox>
											<el-checkbox label="10">10</el-checkbox>
											<el-checkbox label="11">11</el-checkbox>
											<el-checkbox label="12">12</el-checkbox>
											<el-checkbox label="13">13</el-checkbox>
											<el-checkbox label="14">14</el-checkbox>
											<el-checkbox label="15">15</el-checkbox>
											<el-checkbox label="16">16</el-checkbox>
											<el-checkbox label="17">17</el-checkbox>
											<el-checkbox label="18">18</el-checkbox>
											<el-checkbox label="19">19</el-checkbox>
											<el-checkbox label="20">20</el-checkbox>
											<el-checkbox label="21">21</el-checkbox>
											<el-checkbox label="22">22</el-checkbox>
											<el-checkbox label="23">23</el-checkbox>
											<el-checkbox label="24">24</el-checkbox>
											<el-checkbox label="25">25</el-checkbox>
											<el-checkbox label="26">26</el-checkbox>
											<el-checkbox label="27">27</el-checkbox>
											<el-checkbox label="28">28</el-checkbox>
											<el-checkbox label="29">29</el-checkbox>
											<el-checkbox label="30">30</el-checkbox>
											<el-checkbox label="31">31</el-checkbox>
											<el-checkbox label="32">32</el-checkbox>
											<el-checkbox label="33">33</el-checkbox>
											<el-checkbox label="34">34</el-checkbox>
											<el-checkbox label="35">35</el-checkbox>
											<el-checkbox label="36">36</el-checkbox>
											<el-checkbox label="37">37</el-checkbox>
											<el-checkbox label="38">38</el-checkbox>
											<el-checkbox label="39">39</el-checkbox>
											<el-checkbox label="40">40</el-checkbox>
											<el-checkbox label="41">41</el-checkbox>
											<el-checkbox label="42">42</el-checkbox>
											<el-checkbox label="43">43</el-checkbox>
											<el-checkbox label="44">44</el-checkbox>
											<el-checkbox label="45">45</el-checkbox>
											<el-checkbox label="46">46</el-checkbox>
											<el-checkbox label="47">47</el-checkbox>
											<el-checkbox label="48">48</el-checkbox>
											<el-checkbox label="49">49</el-checkbox>
											<el-checkbox label="50">50</el-checkbox>
											<el-checkbox label="51">51</el-checkbox>
											<el-checkbox label="52">52</el-checkbox>
											<el-checkbox label="53">53</el-checkbox>
											<el-checkbox label="54">54</el-checkbox>
											<el-checkbox label="55">55</el-checkbox>
											<el-checkbox label="56">56</el-checkbox>
											<el-checkbox label="57">57</el-checkbox>
											<el-checkbox label="58">58</el-checkbox>
											<el-checkbox label="59">59</el-checkbox>
										</el-checkbox-group>
									</el-col>
								</el-row>
						</el-tab-pane>
						
						<!-- 分钟逻辑 -->
					    <el-tab-pane label="分钟" name="min" >
							<el-radio-group style="margin-left: 0%;" v-model="cronData.minRadio">
							  <el-radio label="1" @change="generate">允许的通配符[, - * /]</el-radio>
							</el-radio-group>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="2" v-model="cronData.minRadio" @change="generate">周期</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startMin" @input="checkOrinput('2')">
									<template v-slot:prepend>从</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.endMin" @input="checkOrinput('2')">
									<template v-slot:append>分钟</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 18px;">
								<el-col :sm="2">
									  <el-radio label="3" v-model="cronData.minRadio" @change="generate">指定</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startMinInit" @input="checkOrinput('3')">
									<template v-slot:prepend>从</template>
									<template v-slot:append>分钟开始</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.minCyc" @input="checkOrinput('3')">
									<template v-slot:prepend>每</template>
									<template v-slot:append>分钟执行一次</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="4" v-model="cronData.minRadio" @change="generate">自定义</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20" style="margin-top: 1px;" >
								<el-col :sm="24">
									<el-checkbox-group v-model="cronData.minCheck" @change="checkOrinput('4')">
										<el-checkbox label="0">00</el-checkbox>
										<el-checkbox label="1">01</el-checkbox>
										<el-checkbox label="2">02</el-checkbox>
										<el-checkbox label="3">03</el-checkbox>
										<el-checkbox label="4">04</el-checkbox>
										<el-checkbox label="5">05</el-checkbox>
										<el-checkbox label="6">06</el-checkbox>
										<el-checkbox label="7">07</el-checkbox>
										<el-checkbox label="8">08</el-checkbox>
										<el-checkbox label="9">09</el-checkbox>
										<el-checkbox label="10">10</el-checkbox>
										<el-checkbox label="11">11</el-checkbox>
										<el-checkbox label="12">12</el-checkbox>
										<el-checkbox label="13">13</el-checkbox>
										<el-checkbox label="14">14</el-checkbox>
										<el-checkbox label="15">15</el-checkbox>
										<el-checkbox label="16">16</el-checkbox>
										<el-checkbox label="17">17</el-checkbox>
										<el-checkbox label="18">18</el-checkbox>
										<el-checkbox label="19">19</el-checkbox>
										<el-checkbox label="20">20</el-checkbox>
										<el-checkbox label="21">21</el-checkbox>
										<el-checkbox label="22">22</el-checkbox>
										<el-checkbox label="23">23</el-checkbox>
										<el-checkbox label="24">24</el-checkbox>
										<el-checkbox label="25">25</el-checkbox>
										<el-checkbox label="26">26</el-checkbox>
										<el-checkbox label="27">27</el-checkbox>
										<el-checkbox label="28">28</el-checkbox>
										<el-checkbox label="29">29</el-checkbox>
										<el-checkbox label="30">30</el-checkbox>
										<el-checkbox label="31">31</el-checkbox>
										<el-checkbox label="32">32</el-checkbox>
										<el-checkbox label="33">33</el-checkbox>
										<el-checkbox label="34">34</el-checkbox>
										<el-checkbox label="35">35</el-checkbox>
										<el-checkbox label="36">36</el-checkbox>
										<el-checkbox label="37">37</el-checkbox>
										<el-checkbox label="38">38</el-checkbox>
										<el-checkbox label="39">39</el-checkbox>
										<el-checkbox label="40">40</el-checkbox>
										<el-checkbox label="41">41</el-checkbox>
										<el-checkbox label="42">42</el-checkbox>
										<el-checkbox label="43">43</el-checkbox>
										<el-checkbox label="44">44</el-checkbox>
										<el-checkbox label="45">45</el-checkbox>
										<el-checkbox label="46">46</el-checkbox>
										<el-checkbox label="47">47</el-checkbox>
										<el-checkbox label="48">48</el-checkbox>
										<el-checkbox label="49">49</el-checkbox>
										<el-checkbox label="50">50</el-checkbox>
										<el-checkbox label="51">51</el-checkbox>
										<el-checkbox label="52">52</el-checkbox>
										<el-checkbox label="53">53</el-checkbox>
										<el-checkbox label="54">54</el-checkbox>
										<el-checkbox label="55">55</el-checkbox>
										<el-checkbox label="56">56</el-checkbox>
										<el-checkbox label="57">57</el-checkbox>
										<el-checkbox label="58">58</el-checkbox>
										<el-checkbox label="59">59</el-checkbox>
									</el-checkbox-group>
								</el-col>
							</el-row>
						</el-tab-pane>
						
						<!-- 小时逻辑 -->
					    <el-tab-pane label="小时" name="hour">
							<el-radio-group style="margin-left: 0%;" v-model="cronData.hourRadio">
							  <el-radio label="1" @change="generate">允许的通配符[, - * /]</el-radio>
							</el-radio-group>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="2" v-model="cronData.hourRadio" @change="generate">周期</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startHour" @input="checkOrinput('2')">
									<template v-slot:prepend>从</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.endHour" @input="checkOrinput('2')">
									<template v-slot:append>小时</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 18px;">
								<el-col :sm="2">
									  <el-radio label="3" v-model="cronData.hourRadio"  @change="generate">指定</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startHourInit" @input="checkOrinput('3')">
									<template v-slot:prepend>从</template>
									<template v-slot:append>小时开始</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.hourCyc" @input="checkOrinput('3')">
									<template v-slot:prepend>每</template>
									<template v-slot:append>小时执行一次</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="4" v-model="cronData.hourRadio"  @change="generate">自定义</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-checkbox-group v-model="cronData.hourCheck" @change="checkOrinput('4')">
									<el-col :sm="24">
										<el-checkbox label="0">00</el-checkbox>
										<el-checkbox label="1">01</el-checkbox>
										<el-checkbox label="2">02</el-checkbox>
										<el-checkbox label="3">03</el-checkbox>
										<el-checkbox label="4">04</el-checkbox>
										<el-checkbox label="5">05</el-checkbox>
										<el-checkbox label="6">06</el-checkbox>
										<el-checkbox label="7">07</el-checkbox>
										<el-checkbox label="8">08</el-checkbox>
										<el-checkbox label="9">09</el-checkbox>
										<el-checkbox label="10">10</el-checkbox>
										<el-checkbox label="11">11</el-checkbox>
										<el-checkbox label="12">12</el-checkbox>
									</el-col>
									<el-col :sm="24">
										<el-checkbox label="13">13</el-checkbox>
										<el-checkbox label="14">14</el-checkbox>
										<el-checkbox label="15">15</el-checkbox>
										<el-checkbox label="16">16</el-checkbox>
										<el-checkbox label="17">17</el-checkbox>
										<el-checkbox label="18">18</el-checkbox>
										<el-checkbox label="19">19</el-checkbox>
										<el-checkbox label="20">20</el-checkbox>
										<el-checkbox label="21">21</el-checkbox>
										<el-checkbox label="22">22</el-checkbox>
										<el-checkbox label="23">23</el-checkbox>
										<el-checkbox label="24">24</el-checkbox>
									</el-col>
								</el-checkbox-group>	
							</el-row>
						</el-tab-pane>
						
						<!-- 日逻辑 -->
					    <el-tab-pane label="日" name="day">
							<el-radio-group style="margin-left: 0%;" v-model="cronData.dayRadio">
							  <el-radio  label="1" @change="generate">允许的通配符[, - * /]</el-radio>
							</el-radio-group>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="5" @change="generate"  v-model="cronData.dayRadio">每月最后一天</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="2" @change="generate"  v-model="cronData.dayRadio">周期</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startDay" @input="checkOrinput('2')">
									<template v-slot:prepend>从</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.endDay" @input="checkOrinput('2')">
									<template v-slot:append>日</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 18px;">
								<el-col :sm="2">
									  <el-radio label="3" @change="generate"  v-model="cronData.dayRadio">指定</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startDayInit" @input="checkOrinput('3')">
									<template v-slot:prepend>从</template>
									<template v-slot:append>日开始</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.dayCyc" @input="checkOrinput('3')">
									<template v-slot:prepend>每</template>
									<template v-slot:append>日执行一次</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="4" @change="generate"  v-model="cronData.dayRadio">自定义</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="24">
									<el-checkbox-group v-model="cronData.dayCheck" @change="checkOrinput('4')">
										<el-checkbox label="1">01</el-checkbox>
										<el-checkbox label="2">02</el-checkbox>
										<el-checkbox label="3">03</el-checkbox>
										<el-checkbox label="4">04</el-checkbox>
										<el-checkbox label="5">05</el-checkbox>
										<el-checkbox label="6">06</el-checkbox>
										<el-checkbox label="7">07</el-checkbox>
										<el-checkbox label="8">08</el-checkbox>
										<el-checkbox label="9">09</el-checkbox>
										<el-checkbox label="10">10</el-checkbox>
										<el-checkbox label="11">11</el-checkbox>
										<el-checkbox label="12">12</el-checkbox>
										<el-checkbox label="13">13</el-checkbox>
										<el-checkbox label="14">14</el-checkbox>
										<el-checkbox label="15">15</el-checkbox>
										<el-checkbox label="16">16</el-checkbox>
										<el-checkbox label="17">17</el-checkbox>
										<el-checkbox label="18">18</el-checkbox>
										<el-checkbox label="19">19</el-checkbox>
										<el-checkbox label="20">20</el-checkbox>
										<el-checkbox label="21">21</el-checkbox>
										<el-checkbox label="22">22</el-checkbox>
										<el-checkbox label="23">23</el-checkbox>
										<el-checkbox label="24">24</el-checkbox>
										<el-checkbox label="25">25</el-checkbox>
										<el-checkbox label="26">26</el-checkbox>
										<el-checkbox label="27">27</el-checkbox>
										<el-checkbox label="28">28</el-checkbox>
										<el-checkbox label="29">29</el-checkbox>
										<el-checkbox label="30">30</el-checkbox>
										<el-checkbox label="31">31</el-checkbox>
									</el-checkbox-group>
								</el-col>
							</el-row>
						</el-tab-pane>
						
						<!-- 月逻辑 -->
						<el-tab-pane label="月份" name="month" >
							<el-radio-group style="margin-left: 0%;" v-model="cronData.monthRadio">
							  <el-radio label="1" @change="generate">允许的通配符[, - * /]</el-radio>
							</el-radio-group>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="2" @change="generate" v-model="cronData.monthRadio">周期</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startMonth" @input="checkOrinput('2')">
									<template v-slot:prepend>从</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.endMonth" @input="checkOrinput('2')">
									<template v-slot:append>月</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 18px;">
								<el-col :sm="2">
									  <el-radio label="3" @change="generate" v-model="cronData.monthRadio">指定</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startMonthInit" @input="checkOrinput('3')">
									<template v-slot:prepend>从</template>
									<template v-slot:append>月开始</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.monthCyc" @input="checkOrinput('3')">
									<template v-slot:prepend>每</template>
									<template v-slot:append>月执行一次</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="4" @change="generate" v-model="cronData.monthRadio">自定义</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="24">
									<el-checkbox-group v-model="cronData.monthCheck" @change="checkOrinput('4')">
										<el-checkbox label="1">01</el-checkbox>
										<el-checkbox label="2">02</el-checkbox>
										<el-checkbox label="3">03</el-checkbox>
										<el-checkbox label="4">04</el-checkbox>
										<el-checkbox label="5">05</el-checkbox>
										<el-checkbox label="6">06</el-checkbox>
										<el-checkbox label="7">07</el-checkbox>
										<el-checkbox label="8">08</el-checkbox>
										<el-checkbox label="9">09</el-checkbox>
										<el-checkbox label="10">10</el-checkbox>
										<el-checkbox label="11">11</el-checkbox>
										<el-checkbox label="12">12</el-checkbox>
									</el-checkbox-group>
								</el-col>
							</el-row>
						</el-tab-pane>
						
						<!-- 周逻辑 -->
						<el-tab-pane label="周(星期)" name="week" >
							<el-radio-group style="margin-left: 0%;" v-model="cronData.weekRadio">
							  <el-radio label="1" @change="generate" >允许的通配符[, - * /]</el-radio>
							</el-radio-group>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="2" @change="generate" v-model="cronData.weekRadio">不指定</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="3" @change="generate" v-model="cronData.weekRadio">周期</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startWeek" @input="checkOrinput('3')">
									<template v-slot:prepend>从</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.endWeek" @input="checkOrinput('3')">
									<template v-slot:append>周</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 18px;">
								<el-col :sm="2">
									  <el-radio label="4" @change="generate" v-model="cronData.weekRadio">指定</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startWeekInit" @input="checkOrinput('4')">
									<template v-slot:prepend>从</template>
									<template v-slot:append>周</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.weekCyc" @input="checkOrinput('4')">
									<template v-slot:prepend>周</template>
									<template v-slot:append>执行一次</template>
								  </el-input>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="5" @change="generate" v-model="cronData.weekRadio">自定义</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="24">
									<el-checkbox-group v-model="cronData.weekCheck" @change="checkOrinput('5')">
										<el-checkbox label="1">周一</el-checkbox>
										<el-checkbox label="2">周二</el-checkbox>
										<el-checkbox label="3">周三</el-checkbox>
										<el-checkbox label="4">周四</el-checkbox>
										<el-checkbox label="5">周五</el-checkbox>
										<el-checkbox label="6">周六</el-checkbox>
										<el-checkbox label="7">周日</el-checkbox>
									</el-checkbox-group>
								</el-col>
							</el-row>
						</el-tab-pane>
						
						<!-- 年逻辑 -->
						<el-tab-pane label="年份" name="year">
							<el-radio-group style="margin-left: 0%;" v-model="cronData.yearRadio">
							  <el-radio label="1" @change="generate" >不指定,允许的通配符[, - * /]非必填</el-radio>
							</el-radio-group>
							<el-row :gutter="20" style="margin-top: 1px;">
								<el-col :sm="2">
									  <el-radio label="2" @change="generate" v-model="cronData.yearRadio">每年</el-radio>
								</el-col>
							</el-row>
							
							<el-row :gutter="20" style="margin-top: 5px;">
								<el-col :sm="2">
									  <el-radio label="3" @change="generate" v-model="cronData.yearRadio">指定</el-radio>
								</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.startYear" @input="checkOrinput('3')">
									<template v-slot:prepend>从</template>
								  </el-input>
								</el-col>
							  <el-col class="line" :sm="1">-</el-col>
								<el-col :sm="9">
								  <el-input placeholder="请输入内容" v-model.number="cronData.endYear" @input="checkOrinput('3')">
									<template v-slot:append>年</template>
								  </el-input>
								</el-col>
							</el-row>
						</el-tab-pane>
						
					  </el-tabs>
					  <el-row :gutter="20" style="margin-top: 18px;">
					  	<el-col :sm="22">
					  		<el-form-item  label="cron表达式">
					  			<el-input  type="text" v-model="cronData.cron"></el-input>
					  		</el-form-item>
					  	</el-col>
						<div style="margin: 0 auto; text-align: center;">
							<el-button  type="primary" @click="reverseClick">cron反解析</el-button>
						</div>
					  </el-row>
					  
					  <el-row :gutter="20" >
					  	<el-col :sm="4">
					  		<el-form-item  label="表达式字段">
					  			<el-input placeholder="*" v-model="cronData.secondColumn">
					  				<template v-slot:append>秒</template>
					  			</el-input>
					  		</el-form-item>
					  	</el-col>
					  	<el-col :sm="3">
					  		<el-input placeholder="*" v-model="cronData.minColumn">
					  			<template v-slot:append>分钟</template>
					  		</el-input>
					  	</el-col>
					  	<el-col :sm="3">
					  		<el-input placeholder="*" v-model="cronData.hourColumn">
					  			<template v-slot:append>小时</template>
					  		</el-input>
					  	</el-col>
					  	<el-col :sm="3">
					  		<el-input placeholder="*" v-model="cronData.dayColumn">
					  			<template v-slot:append>日</template>
					  		</el-input>
					  	</el-col>
					  	<el-col :sm="3">
					  		<el-input placeholder="*" v-model="cronData.monthColumn">
					  			<template v-slot:append>月</template>
					  		</el-input>
					  	</el-col>
					  	<el-col :sm="3">
					  		<el-input placeholder="?" v-model="cronData.weekColumn">
					  			<template v-slot:append>星期</template>
					  		</el-input>
					  	</el-col>
					  	<el-col :sm="3">
					  		<el-input placeholder=""  v-model="cronData.yearColumn">
					  			<template v-slot:append>年</template>
					  		</el-input>
					  	</el-col>
					  </el-row>
					  
					  <el-row :gutter="20" style="margin-top: 1px;">
					  	<el-col :sm="24">
					  		<el-input readonly  type="textarea" :rows="10" resize='none' v-model="cronData.cronTxt" placeholder="近10次执行时间"></el-input>
					  	</el-col>
					  </el-row>
					  <el-row>
						  <section class="content element-doc content">
							  <h3 id="ji-ben-yong-fa">cron表达式介绍</h3>
							  <p>Cron表达式是一个具有时间含义的字符串，字符串以5~6个空格隔开，分为6~7个域，格式为X X X X X X X。其中X是一个域的占位符。最后一个代表年份的域非必须，可省略。单个域有多个取值时，使用半角逗号,隔开取值。每个域可以是确定的取值，也可以是具有逻辑意义的特殊字符。每个域最多支持一个前导零。</p>
						  </section>
					  </el-row>
					  
					  <el-row>
						  <h3 id="ji-ben-yong-fa">cron常用表达式</h3>
						  <el-table :data="cronData.tableData" style="width: 100% background: oldlace" :row-class-name="warning-row">
						      <el-table-column prop="number" label="序号" width="80"></el-table-column>
						      <el-table-column prop="cron" label="cron表达式" width="240"></el-table-column>
						      <el-table-column prop="depict" label="运行周期说明"></el-table-column>
						    </el-table>
					  </el-row>
					  
				</el-form>
		</el-col>
	</div>
</template>

<script setup>
import {ref} from 'vue'
import axios from "axios";
import {moduleEvent as moduleaction} from '../../common/actionrecord'
	let cronData = ref({
		moduleid:2003,
	    activeName: 'second',
		cron: "* * * * * ?",
		secondColumn:"*",
		minColumn:"*",
		hourColumn:"*",
		dayColumn:"*",
		monthColumn:"*",
		weekColumn:"*",
		yearColumn:"",
		secondCheck:[],
		
		startSecond:1,
		endSecond:2,
		startSecondInit:0,
		secondCyc:1,
		
		startMin:1,
		endMin:2,
		startMinInit:0,
		minCyc:1,
		minCheck:[],
		
		startHour:1,
		endHour:2,
		startHourInit:0,
		hourCyc:1,
		hourCheck:[],
		
		startDay:1,
		endDay:2,
		startDayInit:0,
		dayCyc:1,
		dayCheck:[],
		
		startMonth:1,
		endMonth:2,
		startMonthInit:0,
		monthCyc:1,
		monthCheck:[],
		
		startWeek:1,
		endWeek:2,
		startWeekInit:0,
		weekCyc:1,
		weekCheck:[],
		
		startYear:"",
		endYear:"",
		
		currentTab:"",
		secondRadio:"1",
		minRadio:"1",
		hourRadio:"1",
		dayRadio:"1",
		monthRadio:"1",
		weekRadio:"2",
		yearRadio:"1",
		tabsShow:true,
		cronTxt:"",
		tableData: [{
		          number: '1',
		          cron: '0 15 10 ? * *',
		          depict: '每天上午10:15执行任务',
		        }, {
		          number: '2',
		          cron: '0 15 10 * * ?',
		          depict: '每天上午10:15执行任务'
		        }, {
		          number: '3',
		          cron: '0 0 12 * * ?',
		          depict: '每天中午12:00执行任务',
		        }, {
		          number: '4',
		          cron: '0 0 10,14,16 * * ?',
		          depict: '每天上午10:00点、下午14:00以及下午16:00执行任务'
		        }, {
		          number: '5',
		          cron: '0 0/30 9-17 * * ?',
		          depict: '每天上午09:00到下午17:00时间段内每隔半小时执行任务'
		        },
				 {
				  number: '6',
				  cron: '0 * 14 * * ?',
				  depict: '每天下午14:00到下午14:59时间段内每隔1分钟执行任务'
				},
				{
				  number: '7',
				  cron: '0 0-5 14 * * ?',
				  depict: '每天下午14:00到下午14:05时间段内每隔1分钟执行任务'
				},
				{
				  number: '8',
				  cron: '0 0/5 14 * * ?',
				  depict: '每天下午14:00到下午14:55时间段内每隔5分钟执行任务'
				},
				{
				  number: '9',
				  cron: '0 0/5 14,18 * * ?',
				  depict: '每天下午14:00到下午14:55、下午18:00到下午18:55时间段内每隔5分钟执行任务'
				},
				{
				  number: '10',
				  cron: '0 0 12 ? * WED',
				  depict: '每个星期三中午12:00执行任务'
				},
				{
				  number: '11',
				  cron: '0 15 10 15 * ?',
				  depict: '每月15日上午10:15执行任务'
				},
				{
				  number: '12',
				  cron: '0 15 10 L * ?',
				  depict: '每月最后一日上午10:15执行任务'
				},
				{
				  number: '13',
				  cron: '0 15 10 ? * 6L',
				  depict: '每月最后一个星期六上午10:15执行任务'
				},
				{
				  number: '14',
				  cron: '0 15 10 ? * 6#3',
				  depict: '每月第三个星期六上午10:15执行任务'
				},
				{
				  number: '15',
				  cron: '0 10,44 14 ? 3 WED',
				  depict: '每年3月的每个星期三下午14:10和14:44执行任务'
				},
				{
				  number: '16',
				  cron: '0 15 10 ? * * 2022',
				  depict: '2022年每天上午10:15执行任务'
				},
				{
				  number: '17',
				  cron: '0 15 10 ? * * *',
				  depict: '每年每天上午10:15执行任务'
				},
				{
				  number: '18',
				  cron: '0 0/5 14,18 * * ? 2022',
				  depict: '2022年每天下午14:00到下午14:55、下午18:00到下午18:55时间段内每隔5分钟执行任务'
				},
				{
				  number: '19',
				  cron: '0 15 10 ? * 6#3 2022,2023',
				  depict: '2022年至2023年每月第三个星期六上午10:15执行任务'
				},
				{
				  number: '20',
				  cron: '0 0/30 9-17 * * ? 2022-2025',
				  depict: '2022年至2025年每天上午09:00到下午17:30时间段内每隔半小时执行任务务'
				},
				{
				  number: '21',
				  cron: '0 10,44 14 ? 3 WED 2022/2',
				  depict: '从2022年开始，每隔两年3月的每个星期三下午14:10和14:44执行任务'
				}
				]
	})
	moduleaction(cronData.value.moduleid,1);	
	
	let generate = () => {
		var cronTemplet="second min hour day month week year";
	    var second=initSecond();
		var min=initMin();
		var hour=iniHour();
		var day=iniDay();
		if("L"==day){
			second=second=="*" ? 0 : second;
			min=min=="*"? 0 : min;
			hour=hour=="*"? 0 : hour;
		}
		var month=iniMonth();
		var week=iniWeek();
		var year=iniYear();
		cronData.value.cron=cronTemplet
		.replace("second",second)
		.replace("min",min)
		.replace("hour",hour)
		.replace("day",day)
		.replace("month",month)
		.replace("week",week)
		.replace("year",year);
		reverseClick ()
	}
	
	let checkOrinput = (radioVal) => {
		var tab=cronData.value.currentTab;
		if("second"==tab){
			cronData.value.secondRadio=radioVal;
		}
		if("min"==tab){
			cronData.value.minRadio=radioVal;
		}
		if("hour"==tab){
			cronData.value.hourRadio=radioVal;
		}
		if("day"==tab){
			cronData.value.dayRadio=radioVal;
		}
		if("month"==tab){
			cronData.value.monthRadio=radioVal;
		}
		if("week"==tab){
			cronData.value.weekRadio=radioVal;
		}
		if("year"==tab){
			cronData.value.yearRadio=radioVal;
		}
		generate(); 
	}	
	
	/**
	 * 秒处理逻辑
	 */
	let initSecond = () => {
		var seconType=cronData.value.secondRadio;
			if(seconType=="1"){
				return "*";
			}
			if(seconType=="2"){
				return cronData.value.startSecond +"-" +cronData.value.endSecond;
			}
			if(seconType=="3"){
				return cronData.value.startSecondInit+"/"+cronData.value.secondCyc;
			}
			if(seconType=="4"){
				var list=cronData.value.secondCheck
				return list.sort().join();
			}
	}
	
	let initMin = () => {
		var minType=cronData.value.minRadio;
			if(minType=="1"){
				return "*";
			}
			if(minType=="2"){
				return cronData.value.startMin +"-" +cronData.value.endMin;
			}
			if(minType=="3"){
				return cronData.value.startMinInit+"/"+cronData.value.minCyc;
			}
			if(minType=="4"){
				var list=cronData.value.minCheck
				return list.sort().join();
			}
	}
	
	let iniHour = () => {
		var minType=cronData.value.hourRadio;
			if(minType=="1"){
				return "*";
			}
			if(minType=="2"){
				return cronData.value.startHour+"-"+cronData.value.endHour;
			}
			if(minType=="3"){
				return cronData.value.startHourInit+"/"+cronData.value.hourCyc;
			}
			if(minType=="4"){
				var list=cronData.value.hourCheck
				return list.sort().join();
			}
	}
	
	let iniDay = () => {
		var minType=cronData.value.dayRadio;
			if(minType=="1"){
				return "*";
			}
			if(minType=="2"){
				return cronData.value.startDay+"-"+cronData.value.endDay;
			}
			if(minType=="3"){
				return cronData.value.startDayInit+"/"+cronData.value.dayCyc;
			}
			if(minType=="4"){
				var list=cronData.value.dayCheck
				return list.sort().join();
			}
			if(minType=="5"){
				return "L";
			}
	}
	
	let iniMonth = () => {
		var minType=cronData.value.monthRadio;
			if(minType=="1"){
				return "*";
			}
			if(minType=="2"){
				return cronData.value.startMonth+"-"+cronData.value.endMonth;
			}
			if(minType=="3"){
				return cronData.value.startMonthInit+"/"+cronData.value.monthCyc;
			}
			if(minType=="4"){
				var list=cronData.value.monthCheck
				return list.sort().join();
			}
	}
	
	let iniWeek = () => {
		var minType=cronData.value.weekRadio;
			if(minType=="1"){
				return "*";
			}
			if(minType=="2"){
				return "?";
			}
			if(minType=="3"){
				return cronData.value.startWeek+"-"+cronData.value.endWeek;
			}
			if(minType=="4"){
				return cronData.value.startWeekInit+"/"+cronData.value.weekCyc;
			}
			if(minType=="5"){
				var list=cronData.value.weekCheck
				return list.sort().join();
			}
	}
	
	let iniYear = () => {
		var minType=cronData.value.yearRadio;
			if(minType=="1"){
				return "";
			}
			if(minType=="2"){
				return "*";
			}
			if(minType=="3"){
				return cronData.value.startYear+"-"+cronData.value.endYear;
			}
	}
	
	let handleClick = (tab, event) => {
		cronData.value.currentTab=tab.props.name;
		console.log(event) 
	}
	
	/**
	 * 表达式反解析
	 */
	let reverseClick = () => {
		var cron =cronData.value.cron;
		var regs =cron.split(' ');
		if(!cron || regs.length<6 ||regs[5]==""){
			cronData.value.cronTxt="请正确输入cron表达式( ^___^ )"
			return
		}
		cronData.value.secondColumn=regs[0]
		cronData.value.minColumn=regs[1]
		cronData.value.hourColumn=regs[2]
		cronData.value.dayColumn=regs[3]
		cronData.value.monthColumn=regs[4]
		cronData.value.weekColumn=regs[5]
		if(regs.length > 6) {
			cronData.value.yearColumn=regs[6]
		}
		for(var i in regs){
			if(regs[i].indexOf("-")==0 || regs[i].lastIndexOf("-")==0 || 
			regs[i].indexOf("/")==0 || regs[i].lastIndexOf("/")==0){
				cronData.value.cron="";
				return;
			}
		}
		getCronExpression(cronData.value.cron,10).join("\n");
		moduleaction(cronData.value.moduleid,1);	
	}
	
	let getCronExpression= (cron,num) => {
		var result="";
		 axios.get("/api/v1.0/cron", {
			params:{
				cronExpression: cron,
				numTimes: num
			}
		 }).then(res => {
			cronData.value.cronTxt=res.data.data.join("\n")
		 }).catch(err => {
			console.log(err);
		 });
		 return result;
	}

</script>

<style>
.el-table .warning-row {
    background: oldlace;
  }

.el-table .success-row {
    background: #f0f9eb;
  }
</style>